// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import CfIntegrationWithFitbit from "../../blocks/CfIntegrationWithFitbit/src/CfIntegrationWithFitbit";
import Widgets2 from "../../blocks/Widgets2/src/Widgets2";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import CfHealthRecordScreen from "../../blocks/CfHealthRecordScreen/src/CfHealthRecordScreen";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import CfAddselectDoctorScreen from "../../blocks/CfAddselectDoctorScreen/src/CfAddselectDoctorScreen";
import DataImportexportcsv from "../../blocks/DataImportexportcsv/src/DataImportexportcsv";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import CfNativeIntegrationForVideoConsultation from "../../blocks/CfNativeIntegrationForVideoConsultation/src/CfNativeIntegrationForVideoConsultation";
import ReviewPrompt from "../../blocks/ReviewPrompt/src/ReviewPrompt";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import CfToFetchDoctorRegistrationNumber from "../../blocks/CfToFetchDoctorRegistrationNumber/src/CfToFetchDoctorRegistrationNumber";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import OrderCreation6 from "../../blocks/OrderCreation6/src/OrderCreation6";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Payments from "../../blocks/Payments/src/Payments";
import InappGifting from "../../blocks/InappGifting/src/InappGifting";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import GoogleAdsenseIntegration from "../../blocks/GoogleAdsenseIntegration/src/GoogleAdsenseIntegration";
import Gallery from "../../blocks/Gallery/src/Gallery";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Notifications from "../../blocks/notifications/src/Notifications";
import Analytics from "../../blocks/analytics/src/Analytics";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ItemAvailability from "../../blocks/ItemAvailability/src/ItemAvailability";
import DailyTimeLimit from "../../blocks/DailyTimeLimit/src/DailyTimeLimit";
import EkycVerification2 from "../../blocks/EkycVerification2/src/EkycVerification2";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import DashboardGuests from "../../blocks/DashboardGuests/src/DashboardGuests";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import CustomAdvertisements from "../../blocks/CustomAdvertisements/src/CustomAdvertisements";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import CfBlockchainIntegration2 from "../../blocks/CfBlockchainIntegration2/src/CfBlockchainIntegration2";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import Share from "../../blocks/share/src/Share";
import CfGoogleFitIntegration from "../../blocks/CfGoogleFitIntegration/src/CfGoogleFitIntegration";
import ReceiptCustomisation from "../../blocks/ReceiptCustomisation/src/ReceiptCustomisation";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import Sorting from "../../blocks/sorting/src/Sorting";
import Reservations from "../../blocks/Reservations/src/Reservations";
import Location from "../../blocks/location/src/Location";
import MultipageForms2 from "../../blocks/MultipageForms2/src/MultipageForms2";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import FileAttachment from "../../blocks/FileAttachment/src/FileAttachment";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import CfProfileSwitching from "../../blocks/CfProfileSwitching/src/CfProfileSwitching";
import Search from "../../blocks/search/src/Search";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";



const routeMap = {
CfIntegrationWithFitbit:{
 component:CfIntegrationWithFitbit,
path:"/CfIntegrationWithFitbit"},
Widgets2:{
 component:Widgets2,
path:"/Widgets2"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
CfHealthRecordScreen:{
 component:CfHealthRecordScreen,
path:"/CfHealthRecordScreen"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
CfAddselectDoctorScreen:{
 component:CfAddselectDoctorScreen,
path:"/CfAddselectDoctorScreen"},
DataImportexportcsv:{
 component:DataImportexportcsv,
path:"/DataImportexportcsv"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
CfNativeIntegrationForVideoConsultation:{
 component:CfNativeIntegrationForVideoConsultation,
path:"/CfNativeIntegrationForVideoConsultation"},
ReviewPrompt:{
 component:ReviewPrompt,
path:"/ReviewPrompt"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
CfToFetchDoctorRegistrationNumber:{
 component:CfToFetchDoctorRegistrationNumber,
path:"/CfToFetchDoctorRegistrationNumber"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
OrderCreation6:{
 component:OrderCreation6,
path:"/OrderCreation6"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
Payments:{
 component:Payments,
path:"/Payments"},
InappGifting:{
 component:InappGifting,
path:"/InappGifting"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
GoogleAdsenseIntegration:{
 component:GoogleAdsenseIntegration,
path:"/GoogleAdsenseIntegration"},
Gallery:{
 component:Gallery,
path:"/Gallery"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
ItemAvailability:{
 component:ItemAvailability,
path:"/ItemAvailability"},
DailyTimeLimit:{
 component:DailyTimeLimit,
path:"/DailyTimeLimit"},
EkycVerification2:{
 component:EkycVerification2,
path:"/EkycVerification2"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
DashboardGuests:{
 component:DashboardGuests,
path:"/DashboardGuests"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
CustomAdvertisements:{
 component:CustomAdvertisements,
path:"/CustomAdvertisements"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
CfBlockchainIntegration2:{
 component:CfBlockchainIntegration2,
path:"/CfBlockchainIntegration2"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
ReviewAndApproval:{
 component:ReviewAndApproval,
path:"/ReviewAndApproval"},
Share:{
 component:Share,
path:"/Share"},
CfGoogleFitIntegration:{
 component:CfGoogleFitIntegration,
path:"/CfGoogleFitIntegration"},
ReceiptCustomisation:{
 component:ReceiptCustomisation,
path:"/ReceiptCustomisation"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
AutomaticReminders:{
 component:AutomaticReminders,
path:"/AutomaticReminders"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Reservations:{
 component:Reservations,
path:"/Reservations"},
Location:{
 component:Location,
path:"/Location"},
MultipageForms2:{
 component:MultipageForms2,
path:"/MultipageForms2"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
FileAttachment:{
 component:FileAttachment,
path:"/FileAttachment"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
CfProfileSwitching:{
 component:CfProfileSwitching,
path:"/CfProfileSwitching"},
Search:{
 component:Search,
path:"/Search"},
CustomisedOrderStatus:{
 component:CustomisedOrderStatus,
path:"/CustomisedOrderStatus"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},

  Home: {
component:Favourites,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
